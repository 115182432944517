var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[(_vm.show)?_c('div',{staticClass:"table-responsive"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":this.currentPerPage || 10,"columns":1,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative no-headers userlist-table group-list-scroll",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.items,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(user)",fn:function(row){return [_c('b-media',{staticClass:"align-item-center ml-10 pt-0",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","src":"RA","text":row.item.avtar_txt,"variant":row.item.variant}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(row.item.name)+" ")])])]}},{key:"cell(action)",fn:function(row){return [_c('div',{staticClass:"text-right list-icon group-list-record"},[(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.READ,
                subject: _vm.constants.PERMISSIONS_MODEL.LABELS
              })
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.v-primary",modifiers:{"hover":true,"top":true,"v-primary":true}}],attrs:{"title":_vm.$t('tooTip.view')}},[_c('b-link',{attrs:{"to":{ name: 'view-group', params: { id: row.item.id } }}},[_c('feather-icon',{staticClass:"text-primary action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"EyeIcon","size":"18"}})],1)],1):_vm._e(),_c('span',{attrs:{"id":row.item.up_tool}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                  subject: _vm.constants.PERMISSIONS_MODEL.LABELS
                }) &&
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.ADD,
                  subject: _vm.constants.PERMISSIONS_MODEL.LABELS
                })
              )?_c('b-link',{attrs:{"to":{
                name: 'edit-group',
                params: {
                  id: row.item.id
                },
                query: {
                  account_id: _vm.selectedAccount
                }
              }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.update')),expression:"$t('tooTip.update')",modifiers:{"hover":true,"top":true}}],staticClass:"text-primary action-icon cursor-pointer",attrs:{"icon":"EditIcon","size":"18"}})],1):_vm._e()],1),(
              _vm.checkAbility({
                action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                subject: _vm.constants.PERMISSIONS_MODEL.LABELS
              })
            )?_c('span',{attrs:{"id":row.item.del_tool}},[_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-sm-remove",modifiers:{"modal-sm-remove":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('tooTip.delete')),expression:"$t('tooTip.delete')",modifiers:{"hover":true,"top":true}}],staticClass:"text-danger action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteGroup(row.item)}}})],1):_vm._e()])]}}],null,false,772675283)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[(_vm.totalGroups > 0)?_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalGroups,"per-page":_vm.currentPerPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item","no-paging-nav":false,"active-class":_vm.activeClass},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)],1)],1),_c('AreYouSureModal',{attrs:{"data":_vm.deleteData,"onClose":_vm.onClose,"removedUser":_vm.removeGroup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }