<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="this.currentPerPage || 10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        class="position-relative no-headers userlist-table group-list-scroll"
        responsive
        show-empty
        v-if="!show"
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center ml-10 pt-0">
            <template #aside>
              <b-avatar
                size="50"
                src="RA"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.name }}
            </h6>
          </b-media>
        </template>
        <template #cell(action)="row">
          <div class="text-right list-icon group-list-record">
            <span
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.LABELS
                })
              "
              v-b-tooltip.hover.top.v-primary
              :title="$t('tooTip.view')"
            >
              <b-link :to="{ name: 'view-group', params: { id: row.item.id } }">
                <feather-icon
                  icon="EyeIcon"
                  class="text-primary action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                />
              </b-link>
            </span>
            <span :id="row.item.up_tool">
              <b-link
                :to="{
                  name: 'edit-group',
                  params: {
                    id: row.item.id
                  },
                  query: {
                    account_id: selectedAccount
                  }
                }"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.LABELS
                  }) &&
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.LABELS
                  })
                "
              >
                <feather-icon
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  icon="EditIcon"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
              </b-link>
            </span>
            <span
              :id="row.item.del_tool"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.DELETE,
                  subject: constants.PERMISSIONS_MODEL.LABELS
                })
              "
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                style="position: relative; cursor: pointer"
                v-b-modal.modal-sm-remove
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                @click="deleteGroup(row.item)"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalGroups > 0"
              v-model="currentPage"
              :total-rows="totalGroups"
              :per-page="currentPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="handlePageChange"
              :no-paging-nav="false"
              :active-class="activeClass"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      :data="deleteData"
      :onClose="onClose"
      :removedUser="removeGroup"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UtilityService from "@/libs/api/utility-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    AreYouSureModal,
    BTooltip,
    VBTooltip,
    BSkeletonTable
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  props: {
    searchFiled: Object,
    perPage: {
      type: Number,
      default: 10
    },
    selectedAccount: String
  },

  data() {
    return {
      tableColumns: [
        { key: "user", label: this.$t("historyTableColumns.user") },
        { key: "action", label: this.$t("historyTableColumns.action") }
      ],
      totalGroups: 0,
      page: "user",
      user: {},
      roles: [],
      currentPerPage: this.nearestPage(["10", "20", "30"],parseInt(window.innerHeight / 71)),
      // perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      activeClass: "active",
      items: [],
      loggedUser: {},
      show: false,
      isSentInvite: false,
      selectedUser: {},
      isUpdateRole: false,
      deleteData: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
      this.debounceGetAllGroups();
    });
  },
  watch: {
    selectedAccount() {
      this.currentPage = 1;
      this.debounceGetAllGroups();
    },
    perPage(val) {
      if (!this.perPage) {
        this.currentPerPage = 10;
      }
      this.currentPerPage = val;
      const query = Object.assign({}, this.$route.query);

      query.perPage = val;

      this.$router.replace({ query }).catch(() => {});

      this.debounceGetAllGroups();
    },
    currentPage() {},

    searchFiled(newVal, oldVal) {
      this.filter = newVal;
      this.debounceGetAllGroups();
    }
  },
  computed: {},
  methods: {
    debounceGetAllGroups() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllGroups();
      }, 300); // Adjust the delay as necessary
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllGroups();
    },
    updateUrl(e) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    deleteGroup(props) {
      this.c1 = "src/layouts/components/AreYouSureModal.vue";
      this.status = true;
      this.deleteData = { name: props.name, id: props.id };
    },
    async getAllGroups() {
      try {
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          this.filter = {};
          if (this.$route.query.filter) {
            this.filter = {
              field: "all",
              operator: "ilike",
              value: this.$route.query.filter
            };
          } else {
            this.filter = {};
          }
        }
        const query = Object.assign({}, this.$route.query);
        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }
        let filters =
          this.filter && Object.keys(this.filter).length > 0
            ? [this.filter]
            : [];

        let response = await new UtilityService().getAllGroups({
          page: query.page ? Number(query.page) : this.currentPage,
          page_size: query.pageSize
            ? Number(query.pageSize)
            : parseInt(this.currentPerPage),
          filters: filters,
          account_id:
            this.selectedAccount || localStorage.getItem("USER_ACCOUNT_ID")
        });

        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalGroups = response.data.pagination.total_records || 0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },

    onClose() {
      this.debounceGetAllGroups();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeGroup(props) {
      this.deleteData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UtilityService().deleteGroup({
            label_id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("groups.GroupRemovedTitle"),
                text: this.$t("groups.GroupRemovedMsg"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.debounceGetAllGroups();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
.group-list-scroll {
  max-height: calc(100vh - 250px) !important;
  overflow-y: auto;
  .table {
    width: calc(100% - 18px) !important;
  }
}
.table-responsive {
  max-height: calc(100vh - 260px);
  min-height: calc(100vh - 260px);
  overflow-y: auto;
}
</style>
