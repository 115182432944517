<template>
  <div>
    <b-card class="mt-306 position-relative main-card-padding">
      <div class="adduser-btn-row">
        <h4 class="card-title">
          {{ $t("groups.Groups") }}
        </h4>

        <div class="filter-btn-outer-new">
          <b-button
            variant="primary"
            size="sm"
            class="desktop-hidden"
            @click="showFiltermenu = !showFiltermenu"
          >
            <feather-icon icon="FilterIcon" size="15" />
          </b-button>
        </div>
        <div
          class="device-list-mr d-flex flex-wrap filter-card"
          v-if="showFiltermenu"
        >
          <div class="right-side-btn filterbtn">
            <div :class="'mr-15'">
              <div class="addXIcon">
                <feather-icon
                  class="sizeIcon"
                  @click="showFiltermenu = !showFiltermenu"
                  icon="XIcon"
                  size="15"
                />
              </div>
              <label class="search-label mt-1">{{ $t("unit.Entries") }}</label>
              <v-select
                :clearable="false"
                id="vue-select"
                class="per-page-selector d-inline-block fix-83"
                v-model="perPage"
                :options="option"
              />
            </div>
            <div
              class="search-outer-group search-group"
              v-if="
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.LABELS_SUB_ACCOUNT,
                  subject: constants.PERMISSIONS_MODEL.LABELS
                })
              "
            >
              <AccountSearch
                all="true"
                class="unit-search-v2"
                v-model="selectedAccount"
              />
            </div>
            <div :class="'mr-15'">
              <search
                style="float: left"
                class="search-comp no-btm-0"
                v-model="searchFiled"
                :searchColumn="searchColumn"
                :operator="operator"
              ></search>
            </div>
          </div>
        </div>
      </div>
      <GroupList
        :searchFiled="searchFiled"
        :selectedAccount="selectedAccount"
        :perPage="perPage"
      ></GroupList>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BTooltip,
  VBTooltip,
  BFormInput,
  BRow,
  BCol
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Search from "../Common/search.vue";
import GroupList from "@/views/Groups/GroupList.vue";
import constants from "@/utils/constants";
import AccountSearch from "../Unit/Unit-V2/AccountSearch.vue";
export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BTooltip,
    VBTooltip,
    GroupList,
    vSelect,
    BFormInput,
    BRow,
    BCol,
    Search,
    AccountSearch
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      searchColumn: ["name"],
      searchFiled: {},
      operator: "ilike",
      selected: "10",
      perPage: this.nearestPage(
        ["10", "20", "30"],
        parseInt(window.innerHeight / 71)
      ),
      option: [10, 20, 30],
      showFiltermenu: false,
      selectedAccount:
        this.$route.query.account_id || localStorage.getItem("USER_ACCOUNT_ID")
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.tab) {
        this.selected_tab = this.$route.query.tab;
        this.activeTab(this.$route.query.tab, true);
      }
    }
  },
  methods: {
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    }
  },
  mounted() {
    const { perPage } = this.$route.query;

    if (perPage) {
      this.perPage = parseInt(perPage);
    }

    if (this.$route.query.tab) {
      this.selected_tab = this.$route.query.tab;
      this.activeTab(this.$route.query.tab, true);
    }
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.filterbtn {
  align-items: end !important;
}
.search-outer-group {
  max-width: 235px !important;

  margin-right: 1rem !important;
}
@media screen and (min-width: 991px) {
  .search-group {
    margin-bottom: -1rem;
    margin-left: 1rem !important;
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 991px) {
  .search-group {
    margin-bottom: 0px;
  }
}
</style>
